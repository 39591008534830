import { PermissionKey } from '@/core/interfaces/permission.interface';
import { PermissionService } from '@/core/services/permission.service';
import {
  PRODUCT_STATE,
  REQUEST_STATE,
} from '@/shared/utils/constants/state.constants';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
} from '@angular/core';
@Component({
  selector: 'rpp-valid-auth-wrapper',
  standalone: true,
  imports: [CommonModule],
  template: `@if (show) {
      <ng-content></ng-content>
    } @else {
      <ng-content select="[auth-fail]"></ng-content>
    }`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RppValidAuthWrapperComponent {
  permissionKey = input.required<PermissionKey>();

  type = input<'general' | 'byState'>('byState');

  entityState = input<
    keyof typeof REQUEST_STATE | keyof typeof PRODUCT_STATE | undefined
  >(undefined);

  entityName = input<'request' | 'product' | 'collaborator' | null>(null);

  entityAction = input<string>('');

  extraValidation = input<boolean>(true);

  get show() {
    let hasPermission = false;

    if (this.type() === 'general') {
      hasPermission = this.permissionService.getPermissionValidationByKey(
        this.permissionKey()
      );
    } else if (
      this.type() === 'byState' &&
      this.entityName() &&
      this.entityState() &&
      this.entityAction()
    ) {
      hasPermission = this.permissionService.getStateAndPermissionValidation({
        entityName: this.entityName()!,
        entityState: this.entityState()!,
        entityAction: this.entityAction()!,
        permissionKey: this.permissionKey(),
      });
    }
    return hasPermission && this.extraValidation();
  }

  constructor(private permissionService: PermissionService) {}
}
